import React, { useState } from 'react';
import { CgMenuGridO } from "react-icons/cg";

const DropdownMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleMenu}
        className="flex items-center"
      >
        <CgMenuGridO size="32" color='#B8C7E0' />

      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-sm z-50">
          <div className="grid grid-cols-3 gap-4 p-4">
            <a href='https://console.suburbancloud.ng/' className="flex flex-col items-center">
              <img src="/console-icon.png" alt="Acrobat Sign" className="w-[3rem] object-contain" />
              <span className="mt-2 text-sm text-gray-700">Console</span>
            </a>

            <a href='https://portal.suburbancloud.ng/' className="flex flex-col items-center">
              <img src="/portal-icon.png" alt="Stock" className="w-[3rem] object-contain" />
              <span className="mt-2 text-sm text-gray-700">Billing Portal</span>
            </a>


          </div>
          <div className="flex justify-between items-center p-4 border-t border-gray-200">
            <a href="http://suburbancloud.ng/" className="text-sm text-red-500 hover:underline">Home</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;