import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
import { Button } from 'primereact/button';

export default function App() {
    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#000]">
                        <div className="col-12 md:col-7 overflow-hidden bg-[#000]">
                            <img src="/vps/vps-welcome-min.jpg" alt="hero-1" className="" />
                        </div>
                        <div className="col-12 md:col-5 p-6 text-center md:text-left flex align-items-center bg-[#0000] ">
                            <section className=''>
                                <span className="block text-3xl font-thin mb-3 brand-white-text">Virtual Infrastructure Services</span>
                                <p className="mt-0 mb-4 line-height-3 text-white text-sm">Our Virtual Infrastructure Services are meticulously crafted to empower businesses, developers, and essential service providers with unparalleled flexibility, scalability, and full control. Seamlessly integrated with our user-friendly Console, Suburban Cloud's Virtual Services redefine the digital landscape. Whether it's a
                                    <a href='/compute/virtual-machine' className='border-bottom-custom'> Virtual Machine</a>, <a href='/compute/virtual-machine' className='border-bottom-custom'>Desktop as a Service</a> or <a href='/compute/virtual-machine' className='border-bottom-custom'>Virtual Data Centers</a> , our offerings transcend boundaries.</p>
                            </section>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2]">
                        <div className="col-12 md:col-8 overflow-hidden bg-[#e2e2e2]">
                            <img src="canvas-2-min.JPG" alt="hero-1" className="p-10 w-[100%]" />
                        </div>
                        <div className="col-12 md:col-4 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
                            <section className=''>
                                <span className="block text-3xl font-thin mb-3">Web Services</span>
                                <p className="mt-0 mb-4 text-600 line-height-3 text-base"> Our product offering in web services includes a range of essential solutions designed to empower individuals and businesses with a seamless digital presence.</p>
                                <ul className='text-base'>
                                    <li> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline mb-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                        <a href="" className="text-black"> Domain Name's </a></li>
                                    <li> <a href="" className="text-black"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline mb-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg> Hosting </a>
                                    </li>
                                    <li><a href="" className="text-black"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 inline mb-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg> Email</a>
                                    </li>
                                </ul>


                            </section>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>   <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2]">
                    <div className="col-12 md:col-7 overflow-hidden bg-[#e2e2e2]">
                        <img src="voice.svg" alt="hero-1" className="p-10 w-[80%] mt-[3rem]" />
                    </div>
                    <div className="col-12 md:col-5 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] mt-[5rem]">
                        <section className=''>
                            <span className="block text-3xl font-thin mb-3">Platform as a Service</span>
                            <p className="mt-0 mb-4 text-600 line-height-3 text-base">Embark on a transformative journey with Suburban Cloud's Platform as a Service (PaaS). Our PaaS solution is more than a platform; it's a catalyst for innovation. Developers, seamlessly empowered by familiar tools, craft scalable solutions finely tuned that resonate with global standard to the pulse of our local environment. With integrated development and deployment tools, Suburban PaaS reduces time-to-market for applications, ensuring your business thrives in the digital landscape.</p>
                        </section>
                    </div>
                </div></SwiperSlide>

            </Swiper>
        </>
    );
}
