/* eslint-disable jsx-a11y/iframe-has-title */
import { Button } from 'primereact/button';
import TestimonialSlider from '../components/TestimonialSlider';
import ServicesSlider from '../components/ServicesSlider';
import React from 'react';
import { FaPlay } from 'react-icons/fa'; //
const HomePage: React.FC = () => {
  return (
    <div>
      <div className="grid grid-nogutter text-white bg-[#000] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#000] pb-5">
          <section className='fadein animation-duration-1000'>
            <span className="block text-6xl font-300 mb-1 october">Enabling and Connecting</span>
            <div className="text-6xl text-red font-300 mb-3">Communities.</div>
            <p className="mt-0 mb-4 text-[#B8C7E0] line-height-3">Suburban is a Hyperscale Sovereign Cloud Service Provider headquartered in Abuja. We enable and connect communities of all sizes within Nigeria and the rest of the World.</p>

            <Button label="Contact Us" type="button" className="mr-3 p-2 p-button-raise text-white faint-red-dark hover: bg-[#000]" onClick={() => window.location.href = '/contact'}></Button>
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#000]">
          <img src="/hero25.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full object-cover" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="flex flex-wrap justify-between px-5 py-5 bg-black">
        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer hover:opacity-50" onClick={() => window.location.href = 'https://suburbanacademy.ng/'} >
          <h2 className="text-lg mb-2 text-white font-300">Suburban Academy</h2>
          <p className="text-sm text-white">Tech skills to thrive in the 4th Industrial Revolution.</p>
        </div>

        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer hover:opacity-50" onClick={() => window.location.href = 'https://techosphere.ng/'}>
          <h2 className="text-lg mb-2 text-white font-300">Techosphere</h2>
          <p className="text-sm text-white">Connecting Nigerian tech talent to high-impact gigs.</p>
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer hover:opacity-50" onClick={() => window.location.href = 'https://console.suburbancloud.ng/'}>
          <h2 className="text-lg mb-2 text-white font-300">Console</h2>
          <p className="text-sm text-white">Cloud + Console = Control </p>
        </div>
      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#e2e2e2] h-50 -mt-1">

        <ServicesSlider />

      </div>
      <div className="flex flex-wrap justify-between content-center px-10 pb-2 pt-2 bg-[#e7e7e7] ">
       
        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer"  >
        <img src="/logos/google-logo.png" alt="Netflix" className="mx-auto w-40" />
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer">
        <img src="/logos/nutanix-logo.png" alt="Netflix" className="mx-auto w-40" />
        </div>

        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer" >
        <img src="/logos/huawei-logo.png" alt="Netflix" className="mx-auto w-40" />
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 cursor-pointer" >
        <img src="/logos/raspberry-pi-logo.png" alt="Netflix" className="mx-auto w-40" />
        </div>
       

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-cover bg-center h-[25rem] py-10" style={{ backgroundImage: "url('hero-2.jpg')" }}>
        <h2 className='text-3xl text-center mx-auto font-thin border-bottom-custom'> Dont just take our word for it.</h2>
        <div className="flex items-center justify-center h-full w-full">

          <a href="https://www.youtube.com/watch?v=your-video-id" className="relative rounded-full bg-cover bg-center h-24 w-24" style={{ backgroundImage: "url('bg-bg.jpg')" }}>
            <div className="absolute inset-0 flex items-center justify-center bg-[#d50036] bg-opacity-100 rounded-full">
              <FaPlay className="text-white text-4xl ml-2" fill='#e2e2e2' />

            </div>
          </a>
        </div>
      </div>



    </div>

  );
};

export default HomePage;
