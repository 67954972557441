import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-15YMVTKWRW");

    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}
