import { Button } from 'primereact/button';


import React from 'react';
import { FaUbuntu } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import { IoLogoGameControllerA } from "react-icons/io";
import PricingPlan from '../../components/PricingPlan';
import FAQSection from '../../components/FAQSection';
import { FaGithub } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";


const plans = [
  {
      tier: 'Tier 1',
      cpu: '1 vCPU',
      ram: '1GB',
      drive: '25GB SSD',
      bandwidth: '1TB',
      price: '2,750',
  },
  {
      tier: 'Tier 2',
      cpu: '2 vCPU',
      ram: '2GB',
      drive: '50GB SSD',
      bandwidth: '2TB',
      price: '5,500',
  },
  {
      tier: 'Tier 3',
      cpu: '4 vCPU',
      ram: '4GB',
      drive: '80GB SSD',
      bandwidth: '4TB',
      price: '11,000',
  },
  {
      tier: 'Tier 4',
      cpu: '6 vCPU',
      ram: '8GB',
      drive: '320GB SSD',
      bandwidth: '6TB',
      price: '38,500',
  },
];

const faqs = [
  {
    question: 'What is your email hosting service?',
    answer: 'Our email hosting service provides a reliable and secure platform for managing your email communications. It includes features like custom domain email addresses, spam protection, and ample storage space.',
  },
  {
    question: 'Can I use my own domain for email addresses?',
    answer: 'Yes, you can use your own domain to create professional email addresses (e.g., you@yourdomain.com) with our email hosting service.',
  },
  {
    question: 'What security measures are in place for email hosting?',
    answer: 'Our email hosting includes advanced security measures such as spam filtering, virus scanning, and encryption to protect your email communications.',
  },
  {
    question: 'Is there a limit to the number of email accounts I can create?',
    answer: 'The number of email accounts you can create depends on the specific plan you choose. Our plans are designed to accommodate a range of needs, from small businesses to larger organizations.',
  },
  {
    question: 'Do you offer support for email hosting customers?',
    answer: 'Yes, we provide 24/7 customer support for our email hosting customers. You can reach us via email, phone, or live chat for any assistance you may need.',
  },
  {
    question: 'Can I access my email on mobile devices?',
    answer: 'Absolutely. Our email hosting service supports access on various devices, including mobile phones and tablets, ensuring you can stay connected on the go.',
  },
  {
    question: 'How do I set up my email account?',
    answer: 'Setting up your email account is easy with our step-by-step setup guides. Our support team is also available to assist you with the setup process if needed.',
  },
  {
    question: 'What storage options are available for email hosting?',
    answer: 'Our email hosting plans offer generous storage options to accommodate your needs. You can choose a plan that provides the right amount of storage for your email communications.',
  },
  {
    question: 'Is it possible to migrate my existing emails to your service?',
    answer: 'Yes, we offer migration support to help you transfer your existing emails to our email hosting service. Our support team can guide you through the migration process.',
  },
  {
    question: 'What email protocols are supported?',
    answer: 'Our email hosting service supports standard email protocols such as IMAP, POP3, and SMTP, ensuring compatibility with various email clients and devices.',
  },
];

const EmailPage: React.FC = () => {
  return (
    <div>
      <div className="grid grid-nogutter text-800 bg-[#E2E2E2] h-50  pl-0 sm:pl-[10rem]">
        <div className="col-12 md:col-4 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] sm:pl-[10rem]">
          <section className='mt-[0rem]'>
            <span className="block text-5xl font-bold mb-3 text-[#d50036]">Email</span>
            <p className='-mt-[1rem] ml-1 mb-3 text-[0.7rem]'>Manage your email Like a Pro.</p>
            <p className="mt-0 mb-4 text-700 line-height-3 pb-5">Secure your professional and personal identity with a matching email for your domain.

            
            </p>
            <span className="block text-6xl font-thin mb-1 ml-2 -mt-5 text-[#d50036]"><span className='font-bold text-sm'>₦</span>2,750/<span className='font-bold text-sm'>Month</span></span>
            <Button label="SEE PLANS" className="animate-pulse p-button-primary px-10 py-1 rounded-full bg-[#d50036] text-white mt-5 text-sm" />

          </section>
        </div>
        <div className="col-12 md:col-8 overflow-hidden bg-[#e2e2e2]">

          <img src="/email/email-hero.jpg" alt="hero-1" className="md:ml-auto  w-fit block md:m-h-full object-contain opacity-75 h-[80%] mt-5" style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between px-5 py-5 bg-red bg-gradient-custom-2">
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">
          Look Professionnal & Make Your Brand An Inbox Favorite</h2>
          <p className="text-sm text-white">Create your persona with a custom email address — get ultra-secure email hosting with simple to use, ad-free webmail with Suburban Cloud Services.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Business on the go</h2>
          <p className="text-sm text-white">Create your persona with a custom email address — get ultra-secure email hosting with simple to use, ad-free webmail with Suburban Cloud Services.          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Manage your email Like a Pro </h2>
          <p className="text-sm text-white">Create your persona with a custom email address — get ultra-secure email hosting with simple to use, ad-free webmail with Suburban Cloud Services.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Robust anti-spam protection</h2>
          <p className="text-sm text-white">Create your persona with a custom email address — get ultra-secure email hosting with simple to use, ad-free webmail with Suburban Cloud Services.          </p>
        </div>
      </div>
     

      <PricingPlan plans={plans} productLink={"test"}/>
      <FAQSection faqs={faqs} />
      </div>
  );
};


export default EmailPage;
