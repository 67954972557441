import React from 'react';
import { Button } from 'primereact/button';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TiTick } from "react-icons/ti";

const AboutPage: React.FC = () => {
  return (
    <div>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-5xl font-thin mb-2">Who We Are </span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">Suburban is a Hyperscale Sovereign Cloud Service Provider headquartered in Abuja. We enable and connect communities of all sizes within Nigeria and the rest of the World. Businesses, Technology and Media Companies, Software Developers and Government Entities at all levels use Suburban Cloud to run their operations. Educational institutions and Healthcare Service providers trust us to consistently support their value chains of essential services to the public.</p>
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="hero5.jpg" alt="hero-1" className="md:ml-auto block md:h-full animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="hero6.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-5xl font-thin mb-1">Why Choose Suburban Cloud?</span>
            <p className='ml-1 mb-2 mt-2'>Suburban Cloud is licensed and registered with all relevant local regulators and authorities.</p>
            <ul className="mt-0 mb-4 text-700 line-height-3 tracking-5">
              <li className='py-2'><TiTick className='inline'/> Sovereign Cloud.</li>
              <li className='py-2'><TiTick className='inline'/> High level of security and compliance to NDPR.</li>
              <li className='py-2'><TiTick className='inline'/> Low latency due to data localization.</li>
              <li className='py-2'><TiTick className='inline'/> High local support and Talent.</li>
              <li className='py-2'><TiTick className='inline'/> Lower cost and ease of payment due to local currency.</li>
            </ul>
          
          </section>
        </div>

      </div>
      <div className="flex justify-between px-3 py-5 bg-red bg-gradient-custom">
      <span className="block text-2xl font-thin mt-6 text-white border-bottom-custom">Our sovereign credentials</span>
      <div className="flex flex-wrap justify-between px-5 py-5 bg-red ">
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      National Information Technology Development Agency (NITDA)
    </p>
  </div>
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      Nigeria Internet Registration Association (NIRA)
    </p>
  </div>
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      Nigeria Internet Exchange Point (IXPN)
    </p>
  </div>
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      Nigerian Data Protection Commission (NDPCO)
    </p>
  </div>
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      Nigeria Communications Commission (NCC)
    </p>
  </div>
  <div className="w-1/2 lg:w-1/4 p-2">
    <p className="text-sm text-white">
      Nigeria Broadcasting Commission (NBC)
    </p>
  </div>
</div>
      </div>
    </div>
  );
};

export default AboutPage;
