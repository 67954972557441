import { Button } from "primereact/button";

import React, { useEffect, useRef, useState } from "react";
import { FaUbuntu } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import { IoLogoGameControllerA } from "react-icons/io";
import PricingPlan from "../../../components/PricingPlan";
import FAQSection from "../../../components/FAQSection";
import { BreadCrumb } from "primereact/breadcrumb";
import { axiosClient } from "../../../services/axios-client";
import { Product } from "../../../types";


const allPlans = [
  {
    tier: "Tier 1",
    cpu: "1 vCPU",
    ram: "1GB",
    drive: "25GB SSD",
    bandwidth: "200Mbps",
    price: "2,750",
  },
  {
    tier: "Tier 2",
    cpu: "2 vCPU",
    ram: "2GB",
    drive: "50GB SSD",
    bandwidth: "200Mbps",
    price: "5,500",
  },
  {
    tier: "Tier 3",
    cpu: "4 vCPU",
    ram: "4GB",
    drive: "80GB SSD",
    bandwidth: "200Mbps",
    price: "11,000",
  },
  {
    tier: "Tier 4",
    cpu: "6 vCPU",
    ram: "8GB",
    drive: "320GB SSD",
    bandwidth: "200Mbps",
    price: "38,500",
  },
];

const faqs = [
  {
    question: "What is the latency of the virtual machine?",
    answer:
      "Our virtual machines are optimized to ensure minimal latency, providing swift and efficient data processing for your applications.",
  },
  {
    question: "How can I control the power settings of my virtual machine?",
    answer:
      "You can easily adjust the power settings of your virtual machine through console, allowing you to scale performance as needed.",
  },
  {
    question:
      "What monitoring tools are available for system and resource usage?",
    answer:
      "Console provides comprehensive monitoring tools that offer real-time insights into CPU, memory, and network usage, helping you maintain optimal performance.",
  },
  {
    question: "How do I access my virtual machine using SSH?",
    answer:
      "You can securely access and manage your virtual machine via suburban cloud console or remotely using SSH.",
  },
];

const items = [
  { label: "Virtual Infrastructure Services" },
  { label: "Compute" },
  { label: "Virtual Machine" },
];
const home = { label: "Home", url: "/" };

const SESSION_KEY = "Vitual Machine Plans";

const VirtualMachinePage = ({ipv4 = false}: {ipv4?: boolean}) => {
  const plansRef = useRef<HTMLDivElement>(null);

  const [plans, setPlans] = useState(() => {
    const data = sessionStorage.getItem(SESSION_KEY);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  });

  const scrollToPlans = () => {
    if (plansRef.current) {
      plansRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const initPlans = async () => {
      const client = axiosClient();
      const { data } = await client.get("/products/list-virtual-machines");
      const plansList = data.data.products.product.filter(
        (currentPlan: Product) => currentPlan.paytype !== "free"
      );

      setPlans(plansList);
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(plansList));
    };

    if (!plans) {
      initPlans();
    }
  }, []);

  return (
    <div>
      <BreadCrumb
        model={items}
        home={home}
        className="text-sm bg-[#000] border-none"
      />

      <div className="grid grid-nogutter text-800 h-50 pl-0 sm:pl-[10rem] ">
        <div className="col-12 md:col-12 overflow-hidden bg-[#000]">
          <span className="text-7xl font-normal text-center mx-auto w-100 text-white block z-10 relative top-10">
            Virtual Machine
          </span>
          <p className="-mt-[1rem] ml-2 mb-3 text-[0.7rem] text-[#fff] text-center mx-auto relative top-[4rem] z-10">
            {" "}
            Seamlessly integrated with Console for full control.
          </p>
          <Button
            label="SEE PLANS"
            className="p-button-primary px-10 py-[1rem] rounded-full faint-purple-dark  mt-[6rem] text-sm block mx-auto z-10 text-black"
            onClick={scrollToPlans}
          />
          <img
            src="/ssh-min.jpg"
            alt="hero-1"
            className="animate-pulse block object-cover -mt-[0rem] sm:-mt-[5rem] md:-mt-[5rem] lg:-mt-[10rem]"
            style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />{" "}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between px-5 py-3 md:py-5 bg-[#d50036]">
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-normal">Latency</h2>
          <p className="text-sm text-white">
            Ensures minimal delay and rapid data processing.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-normal">Power Control</h2>
          <p className="text-sm text-white">
            Adjust power settings for optimal performance.
          </p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-1 md:mb-2 text-white font-normal">
            System & Resource Monitoring
          </h2>
          <p className="text-sm text-white">
            Track system performance and resource usage.
          </p>
        </div>
        <div className="flex-1 p-2" ref={plansRef}>
          <h2 className="text-lg mb-1 md:mb-2 text-white font-normal">SSH</h2>
          <p className="text-sm text-white">
            Securely access and manage your virtual machine remotely.
          </p>
        </div>
      </div>
      {plans?.length > 0 && plans && (
        <PricingPlan ipv4={ipv4} plans={allPlans} planss={plans} productLink={"test"} />
      )}
      <FAQSection faqs={faqs} />
    </div>
  );
};

export default VirtualMachinePage;
