import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import FAQSection from "../../components/FAQSection";
import axios from "axios";
import { DomainSearchResponse, TLDData, TLDResponse } from "../../types";
import { FormatPrice } from "../../util/methods";
import { ImSpinner10 } from "react-icons/im";
import "../../util/styles.css";

const faqs = [
  {
    question: "What types of domains do you offer?",
    answer:
      "We offer a wide range of domain extensions including .ng, .com, .net, .org, and many more to suit your needs.",
  },
  {
    question: "How do I register a domain?",
    answer:
      "Registering a domain is simple. Just search for your desired domain name on our website, check its availability, and follow the registration steps.",
  },
  {
    question: "Can I transfer my existing domain to your service?",
    answer:
      "Yes, you can transfer your existing domain to our service. Our support team can assist you with the transfer process to ensure it goes smoothly.",
  },
  {
    question: "How long does it take to register a domain?",
    answer:
      "Domain registration is typically instant. Once you complete the registration process, your domain should be active within a few minutes.",
  },
  {
    question: "What is the cost of registering a domain?",
    answer:
      "The cost of registering a domain varies depending on the domain extension (.ng, .com, etc.) and the registration period. Please check our website for the latest pricing details.",
  },
  {
    question: "Can I renew my domain registration?",
    answer:
      "Yes, you can renew your domain registration before it expires. We offer various renewal options to ensure your domain remains active.",
  },
  {
    question: "Do you provide WHOIS privacy protection?",
    answer:
      "Yes, we offer WHOIS privacy protection to keep your personal information private and secure. This service is available for an additional fee.",
  },
  {
    question: "What happens if my domain expires?",
    answer:
      "If your domain expires, it will go through a grace period during which you can still renew it. After the grace period, the domain may become available for others to register.",
  },
  {
    question: "Can I manage my domain settings?",
    answer:
      "Yes, you can manage your domain settings through our user-friendly control panel. This includes updating contact information, DNS settings, and more.",
  },
  {
    question: "Do you offer support for domain-related issues?",
    answer:
      "Yes, we provide 24/7 customer support for all domain-related issues. You can reach us via email, phone, or live chat for assistance.",
  },
];

const tldPrices = [
  { tld: ".ng", price: "₦2,750/Month" },
  { tld: ".com.ng", price: "₦2,500/Month" },
  { tld: ".org.ng", price: "₦2,200/Month" },
  { tld: ".com", price: "₦2,000/Month" },
  { tld: ".info", price: "₦1,800/Month" },
  { tld: ".org", price: "₦3,000/Month" },
];

const SESSION_KEY = "TLDS";

const DomainPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [domainResults, setDomainResults] =
    useState<DomainSearchResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [tldData, setTldData] = useState<TLDResponse | null>(() => {
    const data = sessionStorage.getItem(SESSION_KEY);
    if (data) {
      console.log(data)
      return JSON.parse(data);
    } else {
      return null;
    }
  });

  async function handleSearch() {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://cloud.suburbancloud.ng/v1/hmc-domain/check/${searchTerm}`
      );
      setDomainResults(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // const handleSearch = async () => {
  //   setLoading(true);
  //   const options = {
  //     method: "GET",
  //     url: "https://domainr.p.rapidapi.com/v2/status",
  //     params: {
  //       "mashape-key": "da2b567cd6msh332ba32d2b1073ap13d306jsn5e28dd877552",
  //       domain: searchTerm,
  //     },
  //     headers: {
  //       "x-rapidapi-key": "da2b567cd6msh332ba32d2b1073ap13d306jsn5e28dd877552",
  //       "x-rapidapi-host": "domainr.p.rapidapi.com",
  //     },
  //   };

  //   try {
  //     const response = await axios.request(options);
  //     setDomainResults(response.data.status);
  //   } catch (error) {
  //     console.error("Error checking domain:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    async function getTLDData() {
      try {
        const { data } = await axios.get(
          "https://cloud.suburbancloud.ng/v1/hmc-domain/domain-pricing"
        );
        setTldData(data.data);
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(data.data));
      } catch (error) {
        console.log(error);
      }
    }

    if (tldData == null) {
      getTLDData();
    }
  }, []);

  useEffect(() => {
    console.log(tldData);
  }, [tldData]);

  return (
    <div>
      <div className="grid grid-nogutter text-800 bg-[#d50036] h-50 pl-0 sm:pl-[10rem]">
        <div className="col-12 md:col-12 px-3 py-6 text-center md:text-left flex align-items-center justify-center bg-[#d50036]">
          <section className="text-center">
            <span className="block text-xl md:text-5xl font-normal mb-3 text-[#fff]">
              Domains - Think it. Search it. Get it.
            </span>
            <p className="md:-mt-[1rem] mt-[-10px] ml-2 mb-3 text-[0.7rem] text-white">
              Satisfy the world’s buyers with .com or .ng domain .
            </p>
            <div className="flex flex-col md:flex-row  items-center my-3 md:my-5">
              <input
                type="text"
                className="p-2 border rounded w-full md:mr-2"
                placeholder="Enter domain"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setDomainResults(null);
                }}
              />
              <Button
                label="Search"
                onClick={handleSearch}
                className="px-2 text-center py-2 mt-2 md:mt-0 rounded-sm bg-[#e2e2e2] text-black"
              />
            </div>
            {loading && (
              <p className="flex flex-col gap-1 items-center text-white">
                <ImSpinner10 className="text-lg animate-spin" /> checking.....
              </p>
            )}

            {domainResults && !loading && (
              <div className="md:p-5 p-2">
                <div className="mb-4 p-2 md:px-4 md:py-2 bg-gray-100 rounded">
                  <div
                    key={domainResults.whois}
                    className="flex flex-col md:flex-row justify-between gap-2 items-center bg-white p-2 md:p-4 md:my-3 rounded shadow-sm border border-gray-200"
                  >
                    <div className="text-xl font-semibold">{searchTerm}</div>

                    {domainResults.status === "available" ? (
                      <div className="md:w-[20%] w-full flex flex-col items-center md:items-end">
                        <div className="text-md md:text-xl font-bold md:pr-2 text-green-600">
                          Available
                        </div>
                        <Button
                          label="Add to Cart"
                          className="p-button-primary px-2 md:w-fit  w-full  py-2 rounded-sm bg-[#e2e2e2] text-black"
                          onClick={() =>
                            (window.location.href = `https://portal.suburbancloud.ng/cart.php?a=add&domain=register&query=${searchTerm}`)
                          }
                        />
                      </div>
                    ) : domainResults.status === "unavailable" ? (
                      <div className="text-md md:text-xl font-bold text-red-600">
                        Taken
                      </div>
                    ) : (
                      domainResults.result === "error" && (
                        <div className="text-md md:text-xl font-bold text-red-600">
                          {domainResults.message}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* {domainResults && (
              <div className="p-5">
                <div className="mb-4 p-4 bg-gray-100 rounded">
                  <h3 className="text-gray-800">Results</h3>
                  {domainResults.length > 0 ? (
                    domainResults.map((result: any) => (
                      <div
                        key={result.domain}
                        className="flex justify-between items-center bg-white p-4 my-3 rounded shadow-sm border border-gray-200"
                      >
                        <div className="text-xl font-semibold">
                          {result.domain}
                        </div>
                        <div className="text-right">
                          {result.status === "inactive" ||
                          result.status === "undelegated" ? (
                            <div>
                              <div className="text-2xl font-bold text-green-600">
                                Available
                              </div>
                              <Button
                                label="Add to Cart"
                                className="p-button-primary mt-2"
                              />
                            </div>
                          ) : (
                            <div className="text-2xl font-bold text-red-600">
                              Taken
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No results found</p>
                  )}
                </div>
              </div>
            )} */}
            {/* <div
                  key={tldPrice.tld}
                  className=" border border-white rounded p-0 w-[100%] sm:p-4 w-unset text-center text-white"
                >
                  <div className="text-2xl font-bold">{tldPrice.tld}</div>
                  <div className="text-lg mt-2">{tldPrice.price}</div>
                </div> */}
            <div className="w-full gap-2 p-0 grid-container sm:p-5">
              {tldData &&
                Object.keys(tldData)
                  .filter((current) => !current.includes("gov.ng"))
                  .map((tldName: string) => {
                    const tldInfo = tldData[tldName];

                    return (
                      <div
                        key={tldName}
                        className=" border border-white rounded p-0  sm:p-4  text-center text-white"
                      >
                        <div className="text-2xl font-bold">{tldName}</div>
                        <div className="text-lg mt-2">
                          ₦{FormatPrice(tldInfo.register[1])}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </section>
        </div>
      </div>

      <FAQSection faqs={faqs} />
    </div>
  );
};

export default DomainPage;
