import { Button } from 'primereact/button';


import React from 'react';

import PricingPlan from '../../../components/PricingPlan';
import FAQSection from '../../../components/FAQSection';


const plans = [
  {
      tier: 'STARTERS',
      cpu: '1 vCPU',
      ram: '1GB',
      drive: '25GB SSD',
      bandwidth: '1TB',
      price: '2,750',
  },
  {
      tier: 'Tier 2',
      cpu: '2 vCPU',
      ram: '2GB',
      drive: '50GB SSD',
      bandwidth: '2TB',
      price: '5,500',
  },
  {
      tier: 'Tier 3',
      cpu: '4 vCPU',
      ram: '4GB',
      drive: '80GB SSD',
      bandwidth: '4TB',
      price: '11,000',
  },
  {
      tier: 'Tier 4',
      cpu: '6 vCPU',
      ram: '8GB',
      drive: '320GB SSD',
      bandwidth: '6TB',
      price: '38,500',
  },
];

const faqs = [
  {
    question: 'What is shared hosting?',
    answer: 'Shared hosting is a type of web hosting where multiple websites share the same server resources, making it a cost-effective solution for hosting websites.',
  },
  {
    question: 'Is shared hosting right for my website?',
    answer: 'Shared hosting is ideal for small to medium-sized websites, blogs, and personal projects that do not require extensive resources.',
  },
  {
    question: 'How many websites can I host on a shared hosting plan?',
    answer: 'The number of websites you can host depends on the specific plan you choose. Most shared hosting plans allow for multiple websites to be hosted.',
  },
  {
    question: 'What control panel is provided with your shared hosting plans?',
    answer: 'Our shared hosting plans come with a user-friendly control panel, typically cPanel or a similar interface, to manage your website easily.',
  },
  {
    question: 'Do you offer support for shared hosting customers?',
    answer: 'Yes, we offer 24/7 customer support for our shared hosting customers. You can reach us via email, phone, or live chat.',
  },
  {
    question: 'Can I upgrade my shared hosting plan later?',
    answer: 'Yes, you can easily upgrade your shared hosting plan as your website grows. Our support team can assist you with the upgrade process.',
  },
  {
    question: 'Is my data secure on shared hosting?',
    answer: 'We prioritize security and implement measures such as firewalls, regular updates, and backups to ensure your data is safe on our shared hosting servers.',
  },
  {
    question: 'What kind of uptime can I expect with shared hosting?',
    answer: 'We strive to provide high uptime and reliable performance. Our shared hosting plans typically offer 99.9% uptime.',
  },
];


const SharedHostingPage: React.FC = () => {
  return (
    <div>
      <div className="grid grid-nogutter text-800 bg-[#E2E2E2] h-50 pl-0 sm:pl-[10rem] ">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] sm:pl-[5rem!important]">
          <section className='mt-[0rem]'>
            <span className="block text-5xl font-bold mb-3 text-[#d50036]">Shared Hosting</span>
            <p className='-mt-[1rem] ml-0 mb-3 text-[0.7rem]'>Hosting solution for mid traffic websites and blogs.            </p>
            <p className="mt-0 mb-4 text-700 line-height-3 pb-5">Host your websites in minutes using our reliable and intuitive Linux-powered servers and site platform. With a rich feature set and customer-first support, SUBURBAN CLOUD’s cPanel automated and configurable platform enables customers to focus on growing their businesses.
            </p>
            <span className="block text-6xl font-thin mb-1 ml-2 -mt-5 text-[#d50036]"><span className='font-bold text-sm'>₦</span>2,750/<span className='font-bold text-sm'>Month</span></span>
            <Button label="Coming Soon" className="animate-pulse p-button-primary px-10 py-1 rounded-full bg-[#d50036] text-white mt-5 text-sm" />

          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">

          <img src="/shared/shared-hero2.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full w-fit object-contain opacity-75 h-[80%] mt-5" style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between px-5 py-5 bg-red bg-gradient-custom-2">
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Build your online presence.
          </h2>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Hosting solution for mid traffic websites and blogs.          </h2>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Value hosting supporting multiple websites.</h2>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-thin">Take away complexity and host high-traffic websites.</h2>
        </div>
      </div>
     

      <PricingPlan plans={plans} productLink={"test"}/>
      <FAQSection faqs={faqs} />
      </div>
  );
};


export default SharedHostingPage;
