import React from 'react';
import EarlyAccessForm from '../../../components/EarlyAccessForm';
import { BreadCrumb } from 'primereact/breadcrumb';

const items = [{ label: 'Virtual Infrastructure Services' }, { label: 'Compute' }, { label: 'Serverless' }];
const home = { label: 'Home', url: '/' }



const ServerlessPage: React.FC = () => {
  return (
    <div>
      <BreadCrumb model={items} home={home} className='text-sm bg-none border-none' />
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-5xl font-thin mb-2">Serverless</span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">Embrace a shift in cloud technology, where you no longer worry about managing servers, focusing solely on building and deploying applications. Our Serverless solution seamlessly abstracts away infrastructure complexities, offering a scalable and cost-effective platform for developers. With Suburban Cloud’s Serverless Computing, experience the freedom to innovate without constraints, leveraging the power of the cloud without the burden of server management.

              Join us in redefining possibilities in the serverless era, where your ideas take center stage, and infrastructure fades into the background.
            </p>
            <EarlyAccessForm productName="Serverless" />
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="/dbaas/dbaas-hero.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full mt-5" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
    </div>
  );
};

export default ServerlessPage;
