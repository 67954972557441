import React from 'react';

const VirtualMachinePage: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold text-primary">Virtual Machine</h1>
      <p>Details about Virtual Machine.</p>
    </div>
  );
};

export default VirtualMachinePage;
