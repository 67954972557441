import React from 'react';
import { Button } from 'primereact/button';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import SalesInquiryForm from '../components/SalesInquiryForm';
import GeneralInquiryForm from '../components/GeneralInquiryForm';

const AboutPage: React.FC = () => {
  return (
    <div>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
           
            <GeneralInquiryForm />
           
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="contact-hero.jpg" alt="hero-1" className="md:ml-auto block md:min-h-full animate-pulse 	object-contain" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
   
      <div className="flex justify-between px-5 py-5 bg-red bg-gradient-custom">
        <span className="block text-2xl font-thin mt-6 text-white border-bottom-custom">Contact Details</span>
        <div className="flex justify-between px-5 py-5 bg-red ">

          <div className="flex-1 p-2">
            <p className="text-sm text-white">
            15 Bangui Street,
off Ademola Adetokunbo Street,
Abuja, Nigeria.</p>
          </div>
          <div className="flex-1 p-2">
            <p className="text-sm text-white">support@suburbancloud.ng</p>
          </div>
          <div className="flex-1 p-2">
            <p className="text-sm text-white"> +234 708 000 0044<br></br>
            +234 706 000 0022 </p>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
