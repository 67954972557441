import React from 'react';
import EarlyAccessForm from '../../components/EarlyAccessForm';

const DBaaSPage: React.FC = () => {
  return (
    <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
      <section className=''>
        <span className="block text-5xl font-thin mb-2">Database as a Service</span>
        <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">Unlock a new era of data management with Suburban Cloud’s Database as a Service (DBaaS). Seamlessly handle your Mongo databases without the hassle of intricate setups. Enjoy the Managed DBaaS option, where our experts ensure optimal performance with 24/7 support. For those seeking control, our Self-Managed DBaaS offers the freedom to customize. Elevate your data experience with Suburban Cloud, where DBaaS brings simplicity, scalability, and the assurance of secure, efficient database management.
        </p>
        <EarlyAccessForm productName="DBaaS" />
      </section>
    </div>
    <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
      <img src="/dbaas/dbaas-hero.jpg" alt="hero-1" className="md:ml-auto w-fit block md:m-h-full mt-5" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
    </div>
  </div>
  );
};

export default DBaaSPage;
