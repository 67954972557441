import React from "react";
import EarlyAccessForm from "../../components/EarlyAccessForm";
import { BreadCrumb } from "primereact/breadcrumb";

const items = [
  { label: "Virtual Infrastructure Services" },
  { label: "Compute" },
  { label: "Serverless" },
];
const home = { label: "Home", url: "/" };

const StoragePage: React.FC = () => {
  return (
    <div>
      <BreadCrumb
        model={items}
        home={home}
        className="text-sm bg-none border-none"
      />
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className="">
            <span className="block text-5xl font-thin mb-2">Storage</span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">
              We provide a fortified environment for data storage and retrieval.
              Enjoy the benefits of a secure and scalable storage solution,
              ensuring the integrity of your critical data. Suburban Cloud’s
              storage is designed to meet the highest standards, providing peace
              of mind for your storage requirements in the ever-evolving digital
              landscape.
            </p>
            <EarlyAccessForm productName="Storage" />
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img
            src="/dbaas/dbaas-hero.jpg"
            alt="hero-1"
            className="md:ml-auto block md:m-h-full w-fit mt-5"
            style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default StoragePage;
