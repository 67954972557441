import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
// Import your page components here

import VirtualMachinePage from './pages/VIS/Compute/VirtualMachine'
import ServerlessPage from './pages/VIS/Compute/Serverless'
import DaaSPage from './pages/VIS/Compute/DaaS'
import VirtualDataCenterPage from './pages/VIS/VirtualDataCenter'
import StoragePage from './pages/VIS/Storage'

import HostingPage from './pages/WebServices/Hosting/Hosting'
import WebsitePage from './pages/WebServices/Website'
import SharedHostingPage from './pages/WebServices/Hosting/SharedHosting'
import VPSPage from './pages/WebServices/Hosting/VPS'
import EmailPage from './pages/WebServices/Email'
import DomainPage from './pages/WebServices/Domain'

import DBaaSPage from './pages/PaaS/DBaaS'

import HomePage from './pages/Home'
import AboutPage from './pages/About'
import ContactPage from './pages/Contact'
import SaPage from './pages/Sa'
import { AnalyticsTracker } from './components/AnalyticsTracker';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <AnalyticsTracker/>

      <Navbar />
      <Routes>

        <Route path="/" element={<HomePage/>} />
        <Route path="/compute/serverless" element={<ServerlessPage />} />
        <Route path="/compute/daas" element={<DaaSPage />} />
        <Route path="/compute/virtual-machine-ipv4" element={<VirtualMachinePage ipv4={true} />} />
        <Route path="/compute/virtual-machine-ipv6" element={<VirtualMachinePage />} />
        <Route path="/compute/serverless" element={<ServerlessPage />} />
        <Route path="/virtual-data-center" element={<VirtualDataCenterPage />} />
        <Route path="/storage/ftp" element={<StoragePage />} />
        <Route path="/hosting/shared" element={<SharedHostingPage />} />
        <Route path="/hosting/vps" element={<VPSPage />} />
        <Route path="/hosting" element={<HostingPage />} />
        <Route path="/website" element={<WebsitePage />} />
        <Route path="/email" element={<EmailPage />} />
        <Route path="/domains" element={<DomainPage />} />
        <Route path="/dbaas" element={<DBaaSPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/sa" element={<SaPage />} />

      </Routes>
    </Router>
  );
};

export default AppRouter;
