import React from "react";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const navigateToSocials = (link: string) => (window.location.href = link);

  return (
    <footer className="py-8 flex flex-col justify-center items-center bg-[#e2e2e2] px-5">
      <div className="max-w-full mx-auto  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-[#23272b]">
        {/* Our Services Section */}
        <div className="col-span-1 md:col-span-1">
          <h5 className="font-bold text-lg mb-3">Our Services</h5>
          <ul className="text-sm">
            <li
              className="cursor-pointer"
              onClick={() => (window.location.href = "/hosting/vps")}
            >
              Web Services
            </li>
            <li
              className="cursor-pointer"
              onClick={() => (window.location.href = "compute/virtual-machine")}
            >
              Virtual Infrastructure Services
            </li>
            <li
              className="cursor-pointer"
              onClick={() => (window.location.href = "/dbaas")}
            >
              PaaS
            </li>
          </ul>
        </div>

        {/* About Us Section */}
        <div className="col-span-1 md:col-span-1">
          <h5 className="font-bold text-lg mb-3">Suburban Cloud</h5>
          <ul className="text-sm">
            <li
              className="cursor-pointer"
              onClick={() => (window.location.href = "/about")}
            >
              About Us
            </li>
            <li
              className="cursor-pointer"
              onClick={() => (window.location.href = "/contact")}
            >
              Contact Us
            </li>
          </ul>
        </div>

        {/* Community Section */}
        <div className="col-span-1 md:col-span-1">
          <h5 className="font-bold text-lg mb-3">Community</h5>
          <ul className="text-sm">
            <li
              className="cursor-pointer"
              onClick={() =>
                (window.location.href =
                  "https://portal.suburbancloud.ng/knowledgebase")
              }
            >
              Knowledge Base
            </li>
            <li
              className="cursor-pointer"
              onClick={() =>
                (window.location.href =
                  "https://portal.suburbancloud.ng/announcements")
              }
            >
              News
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="col-span-1 md:col-span-1">
          <h5 className="font-bold text-lg mb-3">Contact Us</h5>
          <ul className="space-y-1 text-sm">
            <li>15 Bangui Street, off Ademola Adetokunbo Street,</li>
            <li>Abuja, Nigeria</li>
            <li>070 8000 0044 or 070 6000 0022</li>
            <li>support@suburbancloud.ng</li>
          </ul>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="text-center mt-8 font-medium text-sm text-[#d50036]">
        <p className="mb-3">
          Copyright 2024 Suburban Cloud. All Rights Reserved
        </p>
        <FaInstagram
          onClick={() =>
            navigateToSocials(
              "https://www.instagram.com/suburbancloud.ng?igsh=czZzdHkxeGw1YjNw"
            )
          }
          className="inline mr-3 text-5xl cursor-pointer"
        />
        <FaLinkedin
          onClick={() =>
            navigateToSocials(
              "https://www.linkedin.com/company/suburban-cloud/"
            )
          }
          className="inline mr-3 text-5xl cursor-pointer"
        />
        <RiTwitterXFill
          onClick={() => navigateToSocials("https://x.com/suburbancloud?s=11")}
          className="inline mr-3 text-5xl cursor-pointer"
        />
        <FaFacebookSquare
          onClick={() => navigateToSocials("www.facebook.com")}
          className="inline mr-3 text-5xl cursor-pointer"
        />
      </div>
    </footer>
  );
};

export default Footer;
