import { Button } from 'primereact/button';
import React, { useRef } from 'react';
import PricingPlan from '../../../components/PricingPlan';
import FAQSection from '../../../components/FAQSection';
import { BreadCrumb } from 'primereact/breadcrumb';


const plans = [
  {
    tier: 'Tier 1',
    cpu: '1 vCPU',
    ram: '1GB',
    drive: '25GB SSD',
    bandwidth: '1TB',
    price: '2,750',
  },
];

const faqs = [
  {
    question: 'What is CaaS?',
    answer: 'Computer as a Service (CaaS) is a cloud-based solution that provides virtual computing resources on a pay-as-you-go basis, allowing users to access their virtual computers from anywhere.',
  },
  {
    question: 'How do I access my virtual computer?',
    answer: 'You can access your virtual computer through console.',
  },
  {
    question: 'Is my data secure with CaaS?',
    answer: 'Yes, your data is secure. We use advanced security measures, including encryption and regular updates, to protect your information.',
  },
  {
    question: 'What kind of support is available?',
    answer: 'We offer 24/7 customer support to assist you with any issues or questions you may have. You can reach us via email, phone, or live chat.',
  },
  {
    question: 'How do I get started with CaaS?',
    answer: 'Getting started is easy. Simply sign up on our website, choose the plan that best suits your needs, and you’ll be up and running in no time.',
  },
];;
const items = [{ label: 'Virtual Infrastructure Services' }, { label: 'CaaS' }];
const home = { label: 'Home', url: '/' }

const DaaSPage: React.FC = () => {

  const plansRef = useRef<HTMLDivElement>(null);

  const scrollToPlans = () => {
    if (plansRef.current) {
      plansRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (

    <div>
      <BreadCrumb model={items} home={home} className='text-sm' />
      <div className="grid grid-nogutter text-800 bg-[#AFA330] h-50 pl-0 sm:pl-[10rem]">

     
        <div className="col-12 md:col-12 overflow-hidden bg-[#AFA330]">
        <span className="text-5xl font-bold  text-center mx-auto text-black block z-10 relative top-[3rem]">End-user Computing on a Pay As You Go basis.</span>
        <p className='-mt-[1rem] ml-2 mb-3 text-[0.7rem] text-[#000] text-center mx-auto relative top-[5rem] z-10'> Seamlessly integrated with Console for full control.</p>
        <Button label="Coming Soon" className=" p-button-primary px-10 py-[1rem] rounded-full bg-[#000] text-white mt-[6rem] text-sm block mx-auto z-10" onClick={scrollToPlans}/>
        <img src="/daas/daas.jpg" alt="hero-1"
    className="block object-cover -mt-[0rem] sm:-mt-[5rem] md:-mt-[5rem] lg:-mt-[12rem]"
    style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }} />        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between px-5 py-5 bg-[#000] mb-5">
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-semibold">Cost Efficiency</h2>
          <p className="text-sm text-white">Reduce upfront costs with our pay-as-you-go model, making advanced computing accessible to a wider audience.</p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-semibold">Security and Updates</h2>
          <p className="text-sm text-white">Enjoy a secure computing environment with regular updates and centralized security management.</p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-semibold">Universal Access</h2>
          <p className="text-sm text-white">Democratize access to computing tools, bridging the digital divide for students, businesses, and researchers.</p>
        </div>
        <div className="flex-1 p-2">
          <h2 className="text-lg mb-2 text-white font-semibold">Scalability</h2>
          <p className="text-sm text-white">Seamlessly scale computing resources to meet evolving demands, ensuring flexibility and growth.</p>
        </div>
      </div>
   
    
      <FAQSection faqs={faqs} />
    </div>
  );
};


export default DaaSPage;
