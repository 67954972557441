import React from 'react';
import EarlyAccessForm from '../../components/EarlyAccessForm';
import { BreadCrumb } from 'primereact/breadcrumb';

const items = [{ label: 'Virtual Infrastructure Services' }, { label: 'Compute' }, { label: 'Virtual Data Center' }];
const home = { label: 'Home', url: '/' }



const VirtualDataCenterPage: React.FC = () => {
  return (
    <div>
      <BreadCrumb model={items} home={home} className='text-sm bg-none border-none' />
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-5xl font-thin mb-2">Virtual Data Center</span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">Suburban Cloud’s Virtual Data Center provides super low latency and high performance infrastructure. At the EDGE proximity and robust global caching partnerships with industry leaders like Google and Netflix make the Suburban VDC the go to destination for high App performance and stability.
            </p>
            <EarlyAccessForm productName="VDC" />
          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="/dbaas/dbaas-hero.jpg" alt="hero-1" className="md:ml-auto block w-fit md:m-h-full mt-5" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
    </div>
  );
};

export default VirtualDataCenterPage;