import React, { useState } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';

interface EarlyAccessFormProps {
  productName: string;
}

const EarlyAccessForm: React.FC<EarlyAccessFormProps> = ({ productName }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await axios.post('/api/early-access', {
        email,
        product: productName,
      });
      if (response.status === 200) {
        setSuccess(true);
        setEmail('');
      } else {
        setError('An error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded shadow-sm">
      <h3 className="text-2xl ">Get Early Access to {productName}</h3>
      <small >Sign up below to receive an exclusive notification when our innovative product is ready.</small>
      <form onSubmit={handleSubmit} className='mt-4'>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
        {success && <p className="text-green-500 text-xs italic">Thank you! You've been added to the early access notification list.</p>}
        <Button type="submit" label="Submit" className="mr-3 p-2 p-button-raise bg-[transparent] text-black border border-[#d50036]" loading={loading} />
      </form>
    </div>
  );
};

export default EarlyAccessForm;